<template>
  <div class="ui grid container-2" id="mssn">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1><span>Our</span> Mission</h1>

          <p>We aspire to expand <span>financial inclusivity</span> through <span>innovative digital technologies</span> and <span>meaningful partnerships</span> to foster <span>sustainable livelihood</span> and create <span>economic value and growth for the country.</span></p>
        </div>
      </div>

      <div class="column div-2">
        <img class="mid-phone" src="@/assets/ad2.png" alt="Tayocash">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2'
}
</script>

<style scoped lang='scss'>
</style>
